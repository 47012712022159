body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiAppBar-root {
  box-shadow: none!important;
}

h1 {
  color: #00afef !important;
  font-size: 64px !important;
  line-height: 1.056 !important;
  font-weight: bold !important;
  font-family: Montserrat, sans-serif !important;
}

h3 {
  color: #003663;
  font-style: normal;
  font-weight: 300 !important;
  font-family: "Montserrat", sans-serif !important;
  letter-spacing: 0em;
  font-size: 40px !important;
  line-height: 1.1 !important;
  text-align: center;
}
h4 {
  font-style: normal!important;
  font-weight: 900!important;
  font-size: 14px!important;
  line-height: 1.93!important;
  font-family: Montserrat, sans-serif!important;
  letter-spacing: 0.8em!important;
  text-align: inherit!important;
  color: #00afef!important;
}

h5 {
  font-size: 40px!important;
  font-weight: bold!important;
  font-style: normal!important;
  color: #1a1b29!important;
  font-family: Montserrat, sans-serif!important;
}
.header-wrap-text {
  max-width: 16rem !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 38px !important;
  line-height: 1.1 !important;
  font-family: Montserrat, sans-serif !important;
  letter-spacing: 0em !important;
  text-align: inherit !important;
  color: #003663 !important;
}
.makeStyles-testimonial-44:before {
  content: "\201c";
  font-family: "Times New Roman", Times, serif;
  display: block;
  font-size: 72px;
  line-height: 50px;
  font-weight: 700;
  text-align: left;
  position: static;
}
a {
  text-decoration: none;
}

.MuiModal-backdrop {
  background: inherit!important;
}
.MuiToolbar-root {
  padding: 0 .75rem;
}

.donate-top a {
  color: white !important;
  margin: 0 .25rem !important;
  padding: 0.25rem 1rem !important;
  background: red !important;
  border-radius: 0 !important;
  text-transform: lowercase !important;
}

.donate-top div > span {
  color: white !important;
  padding: 0 1rem;
  font-size: 2rem;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  line-height: 1.25;
  text-decoration: none;
  font-weight: 500;
}

.donate-top {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 10000;
}